@import "variables";

$pagination-height: 56px;
$table-header-height: 85px;

.responsive-material-table {
  @media screen and (max-width: $medium) {
    padding: 0;
    background-color: initial;
    border: none;
    box-shadow: none;
    margin: 0;

    .mat-table {
      background-color: initial;
      padding: 0;
    }

    .mat-paginator {
      background-color: transparent;
      margin-top: 15px;
    }

    .mat-paginator-page-size {
      display: none;
    }

    .mat-header-row {
      display: none;
    }

    .header-inner {
      padding-left: 0;
      padding-right: 0;
      display: block;
      height: auto;

      .left {
        margin-bottom: 15px;
      }

      .right {
        justify-content: flex-start;
      }
    }


    .mat-row {
      display: block;
      overflow: hidden;
      height: auto;
      min-height: 0;
      margin: 0 0 15px;
      padding: 0 15px;
      border-radius: 4px;
      @include mat-elevation(1);
      position: relative;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .mat-row::after {
      display: none;
    }

    .mat-cell {
      margin: 16px 0;
      min-height: 0;

      &:first-of-type {
        padding-left: 0;
      }
    }

    [data-label] {
      &:before {
        content: attr(data-label);
        display: inline;
        font-weight: normal;
        padding-right: 5px;
        color: mat-color($app-foreground, secondary-text);
      }
    }

    .hidden-on-mobile, .mat-column-select {
      display: none;
    }

    .mat-column-edit {
      position: absolute;
      bottom: 8px;
      right: 5px;
      margin: 0;
      padding: 0;
    }

    .filter-chip-list {
      width: 100%;
      margin-bottom: 15px;
    }
  }
}

.responsive-table {
  .visible-on-mobile {
    display: none;
  }

  @media screen and (max-width: $medium) {
    padding: 0;
    background-color: initial;
    border: none;
    box-shadow: none;
    margin: 0;

    datatable-footer {
      background-color: transparent;

      .page-size {
        display: none;
      }
    }

    datatable-header, .datatable-header {
      padding: 0;
      height: auto;
      margin-bottom: 70px;
    }

    .search-input-container.input-container {
      position: absolute;
      bottom: -55px;
      left: 0;
      width: 100%;
      background-color: mat-color($app-background, card);
    }

    /* collapse table */
    table, thead, tbody, th, td, tr {
      display: block;
    }

    /* Hide table header (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    // panel
    tbody tr {
      height: auto !important;
      background-color: mat-color($app-background, card);
      margin-bottom: 15px;
      padding: 10px 15px;
      border-radius: 4px;
      @include mat-elevation(1);
      position: relative;
    }

    // row
    td {
      padding: 10px 0;
      min-height: 0;
      border-bottom: 1px solid mat-color($app-foreground, divider-lighter);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
        border-bottom: none;
      }
    }

    td:not(.mobile-no-label):not(.table-checkbox) {
      display: flex;
      width: auto;
    }

    // row label
    [data-label] {
      &:before {
        content: attr(data-label);
        display: block;
        font-weight: normal;
        padding-right: 5px;
        color: mat-color($app-foreground, secondary-text);
        width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .edit-column {
      padding: 10px 0 0;
      text-align: center;
      justify-content: center;
    }

    .visible-on-mobile {
      display: block;
    }

    .hidden-on-mobile, .table-checkbox {
      display: none !important;
    }
  }
}
