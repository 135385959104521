@import 'variables';

.template-grid {
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  grid-gap: 20px;

  @media only screen and (max-width: $medium) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  .grid-item {
    padding-top: 60%;
  }

  .grid-label {
    margin-top: 0;
    text-align: left;
  }

  .grid-content {
    border: none;
  }

  .grid-wrapper {
    background-color: mat-color($app-background, card);
    @include mat-elevation(2);
    cursor: pointer;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 4px;
    overflow: hidden;

    &:hover {
      @include mat-elevation(8);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
