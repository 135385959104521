@import "variables";

$ct-text-color: mat-color($app-foreground, secondary-text);
$ct-text-size: 1.3rem;
$ct-series-colors: (
        #42A5F5,
        #66BB6A,
        #FF7043,
        #5C6BC0,
        #8D6E63,
        #FFA726,
        #26A69A,
        #26C6DA,
        #D4E157,
        #8D6E63,
        #BDBDBD,
        #78909C,
        #7E57C2,
        #AB47BC,
        #ef5350
);

// add separators to pie chart slices
.ct-slice-pie {
  fill-opacity: 0.8;
  stroke: #fff;
  stroke-width: 2px;
}

// pie chart labels
.ct-chart-pie .ct-label {
  color: #fff;
  fill: #fff;
  text-shadow: 0 1px rgba(0, 0, 0, 0.7);
  font-weight: 500;
}

// line chart border width
.ct-line {
  stroke-width: 3px;
}

@-webkit-keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ct-chart-line .ct-point, .ct-chart-donut .ct-label {
  animation: fadeInFromNone 0.3s ease-in;
}

.ct-chart-donut .ct-label {
  fill: rgba(250, 250, 250, 0.95);
  text-shadow: 0 1px rgba(0, 0, 0, 0.7);
  font-weight: 500;
}

// chart legend colors
@for $i from 1 to 15 {
  .chart-legend .legend-item:nth-child(#{$i}):before {
    background-color: nth($ct-series-colors, $i);
  }
}

chart.rotate-labels {
  overflow: visible;

  .ct-chart-line {
    overflow: visible;
  }

  .ct-label.ct-horizontal.ct-end {
    position: relative;
    justify-content: flex-end;
    text-align: right;
    transform-origin: 100% 0;
    transform: translate(-100%) rotate(-45deg);
    white-space:nowrap;
  }
}

@import "~chartist/dist/scss/chartist.scss";
@import "chartist-tooltip";
